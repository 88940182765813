<script>
// import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
// import "vue-tel-input/dist/vue-tel-input.css";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      students: [],
      student: {},
      data: {
        role: "etudiant",
        etudiants: [],
      },
      page: 1,
      perPage: 5,
      pages: [],
      studentGrade: [],
      studentAttendes: [],
      submited: false,
      spanView: false,
      semestre: "1",
      trimestres: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
      ],

      title: "Elève",
      items: [
        {
          text: "Elèves",
          href: "/",
        },
        {
          text: "Liste",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      etudiants: {
        required: helpers.withMessage("Choisissez des étudiants", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.getTutorStudents();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.students);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.id.toLowerCase().includes(search) ||
            data.name.toLowerCase().includes(search) ||
            data.email.toLowerCase().includes(search) ||
            data.position.toLowerCase().includes(search) ||
            data.company.toLowerCase().includes(search) ||
            data.country.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    navToFees(student, enroll) {
      this.$router.push({
        path: `/student/fees-slices/${enroll.filiere.classe_id}/${student.id}`,
      });
    },
    getStudentAttendes(student) {
      this.$store
        .dispatch("getRequest", {
          route: `api/getStudentAttendes/${student}`,
          data: this.data,
        })
        .then((response) => {
          this.studentAttendes = response.data.attendances;
        }).catch((error) => console.log(error));
    },
    getStudentGrades(student) {
      this.$store
        .dispatch("getRequest", {
          route: `api/getStudentGrades/${student}/${this.semestre}`,
          data: this.data,
        })
        .then((response) => {
          this.studentGrade = response.data;

          this.studentGrade.forEach((grade) => {
            const devoir = grade.notes.devoir
              ? parseFloat(grade.notes.devoir)
              : 0;
            const compo = grade.notes.compo ? parseFloat(grade.notes.compo) : 0;
            const count = (devoir ? 1 : 0) + (compo ? 1 : 0);

            grade.moyenne = count > 0 ? (devoir + compo) / count : null;

            if (grade.moyenne !== null) {
              if (grade.moyenne >= 16) {
                grade.appreciation = "Très bien";
              } else if (grade.moyenne >= 14) {
                grade.appreciation = "Bien";
              } else if (grade.moyenne >= 12) {
                grade.appreciation = "Assez bien";
              } else if (grade.moyenne >= 10) {
                grade.appreciation = "Passable";
              } else {
                grade.appreciation = "Insuffisant";
              }
            } else {
              grade.appreciation = "Aucune note disponible";
            }
          });
        })

        .catch((error) => console.log(error));
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      // Formatage de la date en français
      return date.toLocaleDateString('fr-FR', options);
    },
    getTutorStudents() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/tutor/students",
          data: this.data,
        })
        .then(
          function (response) {
            self.students = response.data.reduce((acc, student) => {
              student.student_enrollements.forEach((enrollment) => {
                acc.push({
                  nom: student.nom,
                  prenom: student.prenom,
                  annee_scolaire: enrollment.annee_scolaire,
                  filiere: enrollment.filiere.full_classe.name,
                  group: enrollment.filiere.group,
                  ecolage: enrollment.filiere.full_classe.ecolage,
                  student,
                  enrollment,
                });
              });
              return acc;
            }, []);
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },

    setPages() {
      let numberOfPages = Math.ceil(this.students.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-end mb-3">
            <div class="col-lg-4 mb-3">
              <label for="fac" class="form-label"
                >Choisisez le trimestre/semestre pour voir les notes :</label
              >
              <Multiselect
                class="form-control"
                id="fac"
                v-model="semestre"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                :options="trimestres"
              />
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" data-sort="current_value" scope="col">
                    Elève
                  </th>
                  <th scope="col">Classe</th>
                  <th scope="col">Année scolaire</th>
                  <th scope="col">Ecolage</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td>{{ data.nom }} {{ data.prenom }}</td>
                  <td>{{ data.filiere }} {{ data.group }}</td>
                  <td>{{ data.annee_scolaire }}</td>
                  <td>{{ data.ecolage }} CFA</td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0)"
                        @click="navToFees(data.student, data.enrollment)"
                        class="ml-3 link-success fs-15"
                        title="écolage"
                        ><i class="icon-style ri-file-list-3-line"></i
                      ></a>
                      <a
                        href="javascript:void(0)"
                        @click="getStudentGrades(data.student?.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalgrid"
                        class="link-warning fs-15"
                        title="notes"
                        ><i class="ri-edit-2-line"></i
                      ></a>
                      <a
                        href="javascript:void(0)"
                        @click="getStudentAttendes(data.student?.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalgrid2"
                        class="link-primary fs-15"
                        title="notes"
                        ><i class="las la-user-clock "></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModalgrid"
          tabindex="-1"
          aria-labelledby="exampleModalgridLabel"
          aria-modal="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">
                  Relevé de Notes du Trimestre/Semeste {{ semestre }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body card">
                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="table align-middle table-nowrap"
                      id="customerTable"
                    >
                      <thead class="table-light text-muted">
                        <tr>
                          <th scope="col">Matiere</th>
                          <th scope="col">Coeffiscient</th>
                          <th scope="col">Type de Matiere</th>
                          <th scope="col">Note de Devoir</th>
                          <th scope="col">Note de Composition</th>
                          <th scope="col">Moyenne</th>
                          <th scope="col">Appréciation</th>
                          <th scope="col">professeur</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr v-for="note in studentGrade" :key="note.id">
                          <th scope="row">{{ note.nom }}</th>
                          <th scope="row">{{ note.coeff }}</th>
                          <th scope="row">{{ note.type_ue }}</th>
                          <th scope="row">{{ note.notes?.devoir }}</th>
                          <th scope="row">{{ note.notes?.compo }}</th>
                          <th scope="row">{{ note.moyenne }}</th>
                          <th scope="row">{{ note.appreciation }}</th>
                          <th scope="row">{{ note.professeur }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModalgrid2"
          tabindex="-1"
          aria-labelledby="exampleModalgrid2Label"
          aria-modal="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel2">
                  Historique de présence
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body card">
                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="table align-middle table-nowrap"
                      id="customerTable"
                    >
                      <thead class="table-light text-muted">
                        <tr>
                          <th scope="col">Matiere</th>
                          <th scope="col">Date</th>
                          <th scope="col">Heure</th>
                          <th scope="col">Presence</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr v-for="presence in studentAttendes" :key="presence.id">
                          <th scope="row">{{ presence.matiere }}</th>
                          <th scope="row">{{ formatDate(presence.date) }}</th>
                          <th scope="row">{{ presence.heure }}</th>
                          <th scope="row" :class="presence.presence == 'Présent' ? 'text-success' :'text-danger' ">{{ presence.presence }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
